import { useCallback } from "react";
import { PAYMENT_METHOD, useGetBillPaymentOptionsQuery } from "@api/bills";
import type { PaymentMethod, PaymentOption } from "@api/bills/types";
import { ACCOUNT_TYPE } from "@src/bills/constants";

export type PaymentAccountByOption = PaymentOption & {
  paymentMethods: PaymentOption["paymentMethod"][];
};

export const HUMAN_READABLE_PAYMENT_METHOD = {
  ACH: "Bank transfer (ACH)",
  "mail-check": "Mail check",
  "print-check": "Print check",
  "virtual-email-check": "Email a virtual check",
  "virtual-sms-check": "Text a virtual check",
  "mark-as-paid": "Mark as paid",
};

const PAYMENT_METHOD_FLAGS_MAP: Record<string, string> = {
  ACH: "ACH_CHECK_ENABLED",
  "mail-check": "MAIL_CHECK_ENABLED",
  "print-check": "PRINT_CHECK_ENABLED",
  "virtual-email-check": "VIRTUAL_EMAIL_CHECK_ENABLED",
  "virtual-sms-check": "VIRTUAL_SMS_CHECK_ENABLED",
};

export type UsePaymentOptionsInfoParams = {
  skip?: boolean;
  billIds?: string[];
  vendorIds?: string[];
};

export const usePaymentOptionsInfo = ({
  billIds = [],
  vendorIds = [],
  skip,
}: UsePaymentOptionsInfoParams) => {
  const {
    data = [],
    isLoading,
    refetch,
  } = useGetBillPaymentOptionsQuery(
    { billIds, vendorIds },
    {
      skip,
      refetchOnFocus: true,
      selectFromResult: ({ data, isLoading }) => ({
        data: data
          ?.filter((paymentOption) => paymentOption.options)
          .map((paymentOption) => {
            const filteredBankAccounts = paymentOption.options.filter(
              (paymentOption) =>
                paymentOption.customerBankAccount || paymentOption.customerCard
            );

            const accounts = Array.from(
              new Map(
                filteredBankAccounts.map((option) => {
                  const isCustomerCard = !!option.customerCard;
                  const isCreditCard =
                    option.customerPaymentAccount?.isCreditCard ||
                    option.customerBankAccount?.type === "credit" ||
                    false;

                  const id = isCustomerCard
                    ? option.customerCard?.id
                    : option.customerBankAccount?.id;

                  const name = isCustomerCard
                    ? option.customerCard?.fullName
                    : option.customerBankAccount?.name;

                  const mask = `••••${
                    option.customerCard?.mask ||
                    option.customerBankAccount?.mask
                  }`;

                  const type = isCreditCard
                    ? ACCOUNT_TYPE.credit
                    : option.customerBankAccount?.type
                      ? ACCOUNT_TYPE[option.customerBankAccount?.type]
                      : option.customerBankAccount?.type;

                  const markAsPaidOption = paymentOption.options.find(
                    (item) => {
                      const itemId = isCustomerCard
                        ? item.customerCard?.id
                        : item.customerBankAccount?.id;

                      return (
                        item.paymentMethod === PAYMENT_METHOD.MARK_AS_PAID &&
                        itemId === id
                      );
                    }
                  );

                  const label = name + (mask ? ` (${mask})` : "");
                  const value =
                    option.customerCard?.url ||
                    option.customerBankAccount?.url ||
                    "";

                  return [
                    id,
                    {
                      ...option,
                      label,
                      value,
                      mask,
                      type,
                      markAsPaidOption,
                      isCreditCard,
                      customerBankAccount: option.customerBankAccount,
                      customerCard: option.customerCard,
                      hasUnmatchedCardTransactions:
                        option.hasUnmatchedCardTransactions,
                      paymentMethods: paymentOption.options.filter((opt) => {
                        const optId = isCustomerCard
                          ? opt.customerCard?.id
                          : opt.customerBankAccount?.id;

                        const flag =
                          PAYMENT_METHOD_FLAGS_MAP[
                            opt.paymentMethod as keyof typeof PAYMENT_METHOD_FLAGS_MAP
                          ];

                        return (
                          optId === id &&
                          (flag
                            ? (window[flag as keyof typeof window] ?? false)
                            : false)
                        );
                      }),
                    },
                  ];
                })
              ).values()
            );

            return {
              accounts,
            };
          })
          .flat(),
        isLoading,
      }),
    }
  );

  const getPaymentMethods = useCallback(
    (paymentMethods?: PaymentMethod[]) =>
      paymentMethods?.map((paymentMethod) => ({
        value: paymentMethod,
        label: HUMAN_READABLE_PAYMENT_METHOD[paymentMethod],
      })) || [],
    []
  );

  return { data, isLoading, refetch, getPaymentMethods };
};
