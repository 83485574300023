import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Button,
  Card,
  Checkbox,
  ComboBox,
  CurrencyField,
  DatePicker,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Image,
  Label,
  Link,
  Loader,
  Text,
  TextField,
  toast,
  Tooltip,
  Wrapper,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useDialog,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  generateSignature,
  isEqual,
} from "@adaptive/design-system/utils";
import { patchAccountBalance } from "@api/bank-accounts";
import { cancelBillPayment } from "@api/bill-payments";
import { useCreatePaymentV2Mutation } from "@api/bills";
import {
  type BankAccountOption,
  type BillPaymentV2,
  type CustomerBankAccount,
  PAYMENT_METHOD,
  type PaymentMethod,
} from "@api/bills/types";
import { handleErrors } from "@api/handle-errors";
import { CardTransactionField } from "@card-feed/components";
import type {
  CardTransaction,
  CardTransactionFieldOnChangeHandler,
  LinkedCost,
} from "@card-feed/types";
import { BankAccountComboBox } from "@components/bank-account-combobox";
import { Form } from "@components/form";
import { useObjectInternalVersion } from "@hooks/use-object-internal-version";
import { usePaymentOptionsInfo } from "@hooks/use-payment-options-info";
import { BillLienWaivers } from "@lien-waiver/components";
import {
  LIEN_WAIVER_LINKED_STATUS,
  LIEN_WAIVER_STATUS,
} from "@lien-waiver/constants";
import type { LienWaiverCustomer } from "@lien-waiver/types";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import {
  BILL_STATUS,
  PAY_BUTTON_LABEL,
  PAYMENT_STATUS,
  PAYMENT_STEP_FORM_STRINGS,
  STRINGS,
} from "@src/bills/constants";
import {
  billParsedAmountToPay,
  camelCaseBillSelector,
  camelCaseLinesBillSelector,
  isDirtyBillSelector,
  isInPaymentStatusBill,
  paymentsBillV2Selector,
  selectStaticBill,
  vendorBillSelector,
  workflowsBillSelector,
} from "@src/bills/utils";
import {
  AccountSelectDialog,
  type Step as StepSelectAccountDialog,
} from "@src/settings/components/account-select-dialog";
import {
  loadBill,
  recordBillUpdate,
  refetchCurrentBill,
  setBillStatus,
  unarchiveCurrentBill,
} from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useTwoFactorAuth } from "@store/ui";
import { useClientSettings, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { transformKeysToSnakeCase } from "@utils/schema/converters";
import { sum } from "@utils/sum";
import { useVendorAction } from "@vendors/hooks";
import type { Stage } from "@vendors/types";
import lodashIsEmpty from "lodash.isempty";

import { BillAlerts } from "../alerts";
import { ApprovalsSection } from "../approvals-section";
import { Comments } from "../comments";
import { useCycle } from "../cycle-provider";
import { DynamicActions } from "../dynamic-actions";
import { Info } from "../info";
import { Items } from "../items";
import { PurchaseOrders } from "../purchase-orders";
import { PurchaseOrdersOverBudgetAlert } from "../purchase-orders/purchase-orders-over-budget-alert";
import { UpdateSignatureDialog } from "../update-signature-dialog";

import { BillPaymentInfoV2 } from "./bill-payment-info-v2";
import { BillPaymentConfirmationDialog } from "./payment-bill-step-confirmation-dialog";
import { getSignatureString, selectVendorPaymentData } from "./utils";
import {
  validatePayBillAccountBalanceV2,
  validatePayBillClientV2,
  validatePayBillVendor,
} from "./validation";

type Signature = {
  name: string;
  url: string;
  width: number;
  height: number;
};

const DAYS_THRESHOLD = {
  daysBefore: 1,
  daysAfter: 30,
};

const CURRENT_DATE = new Date();

export const PayBillStepFormV2 = () => {
  const { user } = useUserInfo();

  const { checkTwoFactorAuth } = useTwoFactorAuth();

  const { save, close: onClose } = useBillFormActions();

  const [currentSignature, setSignature] = useState<Signature | undefined>();

  const [currentDebitDate, setCurrentDebitDate] = useState<Date | null>(
    CURRENT_DATE
  );

  const [cancellingPayment, setCancellingPayment] = useState(false);

  const hasSignature = !lodashIsEmpty(currentSignature);

  const updateSignatureDialog = useDialog();

  const showPaymentConfirmationDialog = useDialog({
    lazy: true,
  });

  const accountSelectDialog = useMultiStepDialog({
    initialStep: "set-account" as StepSelectAccountDialog,
  });

  const [currentCardTransaction, setCurrentCardTransaction] = useState<
    CardTransaction | undefined
  >();

  const [cancelledPaymentId, setCancelledPaymentId] = useState(null);

  const dispatch = useAppDispatch();

  const isDirty = useAppSelector(isDirtyBillSelector);

  const permissions = useBillFormPermissions();

  const {
    hide: hideEditDebitDateDialog,
    show: showEditDebitDateDialog,
    ...editDebitDateDialog
  } = useDialog({ lazy: true });

  const { canManageLienWaivers, cardFeedEnabled, vendorCreditsEnabled } =
    useClientSettings();

  const cycle = useCycle();

  const billLines = useAppSelector(camelCaseLinesBillSelector);

  const workflows = useAppSelector(workflowsBillSelector);

  const billVendor = useAppSelector(vendorBillSelector);

  const billPayments = useAppSelector<BillPaymentV2[]>(paymentsBillV2Selector);

  const { fetchById: fetchVendor, showVendorById } = useVendorAction();

  const [triggerCreatePayment, { isLoading: isLoadingMarkingAsPaid }] =
    useCreatePaymentV2Mutation();

  const billCustomerUrls = useMemo(
    () =>
      Array.from(
        new Set(
          billLines
            .filter((line) => !line.deleted && line.customer?.url)
            .map((line) => line.customer?.url)
        )
      ),
    [billLines]
  );

  const validBillPayments = useMemo(
    () =>
      billPayments.filter(
        (payment) =>
          payment.status !== PAYMENT_STATUS.CANCELLED &&
          payment.status !== PAYMENT_STATUS.FAILED
      ) || [],
    [billPayments]
  );

  const getSignature = useCallback(
    (signatureName?: string) =>
      generateSignature(signatureName || user.full_name),
    [user.full_name]
  );

  const {
    id,
    url: billUrl,
    balance,
    docNumber,
    isArchivedByUser,
    tooManyLines,
    reviewStatus,
    isVendorCredit,
    shouldShowPurchaseOrders,
    billLienWaiverTemplate,
    lienWaivers,
    paymentDetails,
  } = useAppSelector(camelCaseBillSelector);

  const rawBill = useAppSelector(selectStaticBill, isEqual);

  const amountToPay = useAppSelector(billParsedAmountToPay);

  const isInPaymentStatus = isInPaymentStatusBill(reviewStatus);

  const enhancedShouldShowPurchaseOrders =
    shouldShowPurchaseOrders &&
    permissions.canViewPurchaseOrder &&
    !isInPaymentStatus;

  const billInternalVersion = useObjectInternalVersion(rawBill);

  const isPaid = balance === 0;

  const lienWaiver = useMemo(
    () => lienWaivers.find((item) => !item.billPayment),
    [lienWaivers]
  );

  const enhancedCanPay = useMemo(
    () => permissions.canPayBill && !isPaid,
    [permissions.canPayBill, isPaid]
  );

  const {
    data: paymentOptions,
    getPaymentMethods,
    isLoading: paymentOptionsLoading,
    refetch: refetchPaymentOptions,
  } = usePaymentOptionsInfo({
    billIds: id ? [id] : [],
    vendorIds: billVendor?.id ? [billVendor.id] : [],
    skip: !enhancedCanPay || !id,
  });

  const paymentOptionsForBill = useMemo(
    () => (paymentOptions.length ? paymentOptions[0] : undefined),
    [paymentOptions]
  );

  const {
    vendor,
    addressOptions: vendorAddresses,
    recipientOptions: vendors,
  } = useAppSelector(selectVendorPaymentData);

  const navigate = useNavigate();
  const location = useLocation();

  const onViewPayment = useCallback(
    (id: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("billPaymentId", id);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    },
    [location.pathname, location.search, navigate]
  );

  const [payment, setPayment] = useState({
    method:
      paymentDetails.method ||
      ((window.ACH_CHECK_ENABLED
        ? PAYMENT_METHOD.ACH
        : PAYMENT_METHOD.MARK_AS_PAID) as PaymentMethod),
    vendor: paymentDetails.vendor || vendor.email || vendors[0]?.value || "",
    accountBalance: paymentDetails.accountBalance || "",
    account: paymentDetails.account || "",
    vendorBankingAch:
      paymentDetails.vendorBankingAch || vendor?.banking?.url || "",
    vendorEmail: paymentDetails.vendorEmail || vendor.email || "",
    vendorPhoneNumber:
      paymentDetails.vendorPhoneNumber || vendor.phoneNumber || "",
    signature: paymentDetails.signature || currentSignature?.url || "",
    currentDebitDate: paymentDetails.currentDebitDate || CURRENT_DATE,
    customerLienWaivers: paymentDetails.customerLienWaivers || [],
    payWhenLienWaiverIsSigned:
      paymentDetails.payWhenLienWaiverIsSigned || false,
  });

  const achShouldBeRequested =
    payment.method === PAYMENT_METHOD.ACH && vendor.achCanBeRequested;

  const paymentVendorEmail = useMemo(
    () => payment.vendorEmail || billVendor.email || "",
    [payment, billVendor]
  );

  const isPrintCheckEnabled =
    payment.method === PAYMENT_METHOD.PRINT_CHECK && payment.accountBalance;

  const isACHEnabled = !!(
    payment.method === PAYMENT_METHOD.ACH && payment.accountBalance
  );

  const isMailCheckEnabled = !!(
    payment.method === PAYMENT_METHOD.MAIL_CHECK && payment.accountBalance
  );

  const singlePayment =
    isPaid && validBillPayments.length === 1 ? validBillPayments[0] : null;

  const haveLinkedLienWaiver =
    lienWaiver &&
    LIEN_WAIVER_LINKED_STATUS.some((status) => status === lienWaiver.status);

  const curriedOpenVendor = useCallback(
    (stage: Stage) => () => {
      showVendorById(billVendor.id, stage);
    },
    [billVendor.id, showVendorById]
  );

  const ACHPaymentHasNoACH =
    payment.method == PAYMENT_METHOD.ACH &&
    // if user adds ACH then it's a regular ACH payment
    !payment.vendorBankingAch;

  const selectedAccountBalance = useMemo(
    () =>
      payment.accountBalance
        ? paymentOptionsForBill?.accounts.find(
            (option) => option.value === payment.accountBalance
          )
        : undefined,
    [payment.accountBalance, paymentOptionsForBill]
  );
  const selectedPaymentMethod = useMemo(
    () =>
      selectedAccountBalance?.paymentMethods.find(
        (item) => item.paymentMethod === payment.method
      ),
    [selectedAccountBalance, payment.method]
  );

  const highlightedDates = useMemo(() => {
    if (!selectedPaymentMethod?.paymentSchedules?.length) return undefined;
    const nextScheduledPayment = selectedPaymentMethod.paymentSchedules[0];
    return [
      {
        message: "Process date",
        matcher: nextScheduledPayment?.processOn as Date,
      },
      {
        message: "Delivery date",
        matcher: {
          from: nextScheduledPayment?.expectedDeliveryAfter as Date,
          to: nextScheduledPayment?.expectedDeliveryBefore as Date,
        },
      },
    ];
  }, [selectedPaymentMethod?.paymentSchedules]);

  const canBenReviewedAndPaid = useMemo(() => {
    const allowedPaymentMethods = [
      PAYMENT_METHOD.ACH,
      PAYMENT_METHOD.MAIL_CHECK,
      PAYMENT_METHOD.PRINT_CHECK,
    ];
    return (
      selectedAccountBalance?.paymentMethods.some(
        (item) => item.paymentMethod === PAYMENT_METHOD.ACH
      ) &&
      allowedPaymentMethods.some(
        (paymentMethod) => payment.method === paymentMethod
      )
    );
  }, [payment.method, selectedAccountBalance]);

  const curriedMarkAsPaid = () => {
    if (selectedAccountBalance?.markAsPaidOption) {
      checkTwoFactorAuth(async () => {
        dispatch(setBillStatus("loading"));
        try {
          await triggerCreatePayment([
            {
              bills: [billUrl],
              options: {
                appliedAmount: amountToPay,
                customerBankAccount:
                  selectedAccountBalance.customerBankAccount?.url,
                customerCard: selectedAccountBalance.customerCard?.url,
                customerPaymentAccount:
                  selectedAccountBalance.markAsPaidOption
                    ?.customerPaymentAccount?.url,
                vendorBankAccount:
                  selectedAccountBalance.markAsPaidOption?.vendorBankAccount
                    ?.url,
                appliedCardTransaction: currentCardTransaction?.url,
                paymentMethod: PAYMENT_METHOD.MARK_AS_PAID,
                appliedSignature:
                  payment.method === PAYMENT_METHOD.MAIL_CHECK
                    ? getSignatureString(payment.signature)
                    : undefined,
                processPrerequisites:
                  selectedAccountBalance.markAsPaidOption?.processPrerequisites,
                appliedProcessOn: formatDate(
                  payment.currentDebitDate,
                  "yyyy-MM-dd"
                ),
              },
              lienWaivers: payment.customerLienWaivers.map((lienWaiver) => ({
                ...lienWaiver,
                lienWaiverTemplateId:
                  lienWaiver.sendLienWaiverOnPayment &&
                  lienWaiver?.lienWaiverTemplate
                    ? `${parseRefinementIdFromUrl(
                        lienWaiver?.lienWaiverTemplate
                      )}`
                    : null,
                status:
                  !lienWaiver.sendLienWaiverOnPayment &&
                  lienWaiver.status !== LIEN_WAIVER_STATUS.NOT_REQUIRED
                    ? LIEN_WAIVER_STATUS.NOT_SELECTED
                    : lienWaiver.status,
              })),
            },
          ]).unwrap();
          setCurrentCardTransaction(undefined);
          dispatch(
            refetchCurrentBill([
              "bill_payments_v2",
              "amount_to_pay",
              "balance",
              "payment_details",
            ])
          );
          toast.success(
            `${PAYMENT_STEP_FORM_STRINGS.PAID_SUCCESSFULLY} #${docNumber}!`
          );
          analytics.track("billPay", { billId: id });
        } catch (e) {
          handleErrors(e);
        } finally {
          dispatch(setBillStatus("loaded"));
        }
      });
    }
  };

  // TODO: Refactor outdated approach for validation
  const fromAccountValidation = useMemo(() => {
    if (isPaid) return;
    if (selectedAccountBalance?.customerBankAccount) {
      return validatePayBillAccountBalanceV2(
        selectedAccountBalance.customerBankAccount
      );
    }

    const accountBalances =
      paymentOptionsForBill?.accounts
        .filter(
          (account) =>
            account.customerBankAccount?.type === "depository" &&
            account.customerBankAccount?.checkbookAccountIsActive
        )
        .map((account) => account.customerBankAccount) || [];

    return validatePayBillClientV2(accountBalances as CustomerBankAccount[]);
  }, [isPaid, selectedAccountBalance, paymentOptionsForBill?.accounts]);

  // TODO: Refactor outdated approach for validation
  const vendorValidation = useMemo(() => {
    if (isPaid) return;
    return validatePayBillVendor({
      paymentMethod: payment.method,
      vendorEmail: paymentVendorEmail,
      vendor: { ...vendor },
    });
  }, [isPaid, payment.method, vendor, paymentVendorEmail]);

  const hasValidationErrors =
    (fromAccountValidation?.failures?.length || 0) > 0 ||
    !amountToPay ||
    (payment.method === PAYMENT_METHOD.MAIL_CHECK && !vendorValidation?.ok);

  const isPaymentEnabled =
    !hasValidationErrors &&
    (isPrintCheckEnabled || isACHEnabled || isMailCheckEnabled);

  const notAllowedToPayTooltip = !enhancedCanPay
    ? STRINGS.ACTION_NOT_ALLOWED
    : undefined;

  const vendorAddressTooltip =
    payment.method === PAYMENT_METHOD.MAIL_CHECK && vendorValidation?.ok
      ? undefined
      : vendorValidation?.failures[0];

  const billAlreadyPaidTooltip =
    reviewStatus === BILL_STATUS.PAID
      ? PAYMENT_STEP_FORM_STRINGS.BILL_ALREADY_PAID_MESSAGE
      : undefined;

  const accountBalanceRequiredTooltip =
    payment.method !== PAYMENT_METHOD.MARK_AS_PAID && !payment.accountBalance
      ? PAYMENT_STEP_FORM_STRINGS.SELECT_ACCOUNT_TO_PAY
      : undefined;

  const paymentAccountRequiredTooltip =
    payment.method === PAYMENT_METHOD.MARK_AS_PAID && !payment.account
      ? PAYMENT_STEP_FORM_STRINGS.SELECT_ACCOUNT_FROM_WHICH_BILL_PAID
      : undefined;

  const amountToPayTooltip = amountToPay
    ? undefined
    : PAYMENT_STEP_FORM_STRINGS.AMOUNT_TO_PAY_REQUIRED;

  const amountToPayTooHighTooltip = amountToPay
    ? undefined
    : PAYMENT_STEP_FORM_STRINGS.PAYMENT_AMOUNT_T0_HIGH;

  const cantReviewAndPayTooltip = !canBenReviewedAndPaid
    ? PAYMENT_STEP_FORM_STRINGS.REVIEW_AND_PAY_UNAVAILABLE_MESSAGE
    : undefined;

  const validationTooltip =
    notAllowedToPayTooltip ||
    billAlreadyPaidTooltip ||
    accountBalanceRequiredTooltip ||
    vendorAddressTooltip ||
    paymentAccountRequiredTooltip ||
    amountToPayTooltip ||
    amountToPayTooHighTooltip;

  const renderLabel = (label: string) =>
    cancellingPayment ? <Loader /> : label;

  const getPrimaryActionButtons = () => {
    let buttonProps = {
      onClick: () => {
        checkTwoFactorAuth(async () => {
          showPaymentConfirmationDialog.show();
        });
      },
      disabled:
        isArchivedByUser ||
        !isPaymentEnabled ||
        !enhancedCanPay ||
        paymentOptionsLoading ||
        isLoadingMarkingAsPaid ||
        hasValidationErrors ||
        reviewStatus === BILL_STATUS.PAID ||
        !canBenReviewedAndPaid,

      children: renderLabel(PAY_BUTTON_LABEL.REVIEW_AND_PAY),
    };

    let tooltipMessage = validationTooltip || cantReviewAndPayTooltip || null;

    if (isArchivedByUser) {
      buttonProps = {
        onClick: onUnarchive,
        children: PAYMENT_STEP_FORM_STRINGS.RESTORE_TITLE,
        disabled: false,
      };
    } else if (isVendorCredit) {
      buttonProps = {
        onClick: onClose,
        disabled: false,
        children: PAYMENT_STEP_FORM_STRINGS.CLOSE_TITLE,
      };
      tooltipMessage = null;
      // bill is not archived and isn't vendor credit
    } else if (payment.method === PAYMENT_METHOD.ACH) {
      if (reviewStatus === BILL_STATUS.ACH_INFO_REQUESTED) {
        buttonProps = {
          disabled: false,
          onClick: () => onCancelPayment(singlePayment?.id),
          children: renderLabel(PAYMENT_STEP_FORM_STRINGS.CANCEL_PAYMENT_TITLE),
        };
        tooltipMessage = !permissions.canPayBill
          ? STRINGS.ACTION_NOT_ALLOWED
          : null;
      } else {
        if (ACHPaymentHasNoACH) {
          buttonProps = {
            ...buttonProps,
            children: renderLabel(PAY_BUTTON_LABEL.REVIEW_AND_PAY),
          };
        }
      }
    }

    if (isDirty) {
      buttonProps.disabled = true;
      tooltipMessage = PAYMENT_STEP_FORM_STRINGS.UNSAVED_CHANGES_ALERT;
    }

    if (tooltipMessage && !buttonProps.disabled) {
      buttonProps.disabled = true;
    }

    const content = <Button {...buttonProps} size="lg" />;

    return tooltipMessage ? (
      <Tooltip message={tooltipMessage} placement="left">
        {content}
      </Tooltip>
    ) : (
      content
    );
  };

  const onUnarchive = useEvent(() => {
    cycle.disable();
    dispatch(unarchiveCurrentBill());
  });

  const onChangeLienWaiverRequest = useEvent(() =>
    dispatch(refetchCurrentBill(["lien_waivers"]))
  );

  const mapLienWaiverCustomer = useCallback(
    (
      customer?: string,
      lienWaiverTemplateUrl?: string,
      sendLienWaiverOnPayment?: boolean
    ) => {
      const lienWaiverTemplate =
        lienWaiverTemplateUrl &&
        lienWaiverTemplateUrl !== LIEN_WAIVER_STATUS.NOT_REQUIRED
          ? lienWaiverTemplateUrl
          : undefined;

      const status = (
        !lienWaiverTemplateUrl
          ? LIEN_WAIVER_STATUS.NOT_SELECTED
          : lienWaiverTemplateUrl === LIEN_WAIVER_STATUS.NOT_REQUIRED
            ? lienWaiverTemplateUrl
            : undefined
      ) as LienWaiverCustomer["status"];

      return {
        customer,
        lienWaiverTemplate,
        status,
        sendLienWaiverOnPayment:
          (lienWaiverTemplate &&
            (sendLienWaiverOnPayment || payment.payWhenLienWaiverIsSigned)) ||
          false,
      } as LienWaiverCustomer;
    },
    [payment.payWhenLienWaiverIsSigned]
  );

  const onChangeLienWaiverTemplate = useEvent(
    (value: string, extra?: { customer?: string }) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === extra?.customer
            ? mapLienWaiverCustomer(
                extra?.customer,
                value,
                item.sendLienWaiverOnPayment
              )
            : item
        ),
      }));
    }
  );

  const onChangeRequestOnPayment = useEvent(
    (value: boolean, extra?: { customer?: string }) => {
      setPayment((prev) => ({
        ...prev,
        customerLienWaivers: prev.customerLienWaivers.map((item) =>
          item.customer === extra?.customer
            ? { ...item, sendLienWaiverOnPayment: value }
            : item
        ),
      }));
    }
  );

  const requestOnPayment = useMemo(
    () => ({
      onChange: onChangeRequestOnPayment,
      disabled: payment.payWhenLienWaiverIsSigned,
    }),
    [onChangeRequestOnPayment, payment.payWhenLienWaiverIsSigned]
  );

  const onSetPayWhenLienWaiverIsSigned = useEvent((value: boolean) =>
    setPayment((prev) => ({
      ...prev,
      payWhenLienWaiverIsSigned: value,
      customerLienWaivers: prev.customerLienWaivers.map((item) =>
        value && item.lienWaiverTemplate
          ? { ...item, sendLienWaiverOnPayment: value }
          : item
      ),
    }))
  );

  const onChangePaymentAmount = useEvent((value) =>
    dispatch(recordBillUpdate({ amount_to_pay: value }))
  );

  const onCancelPayment = useEvent(async (billPaymentId) => {
    setCancellingPayment(true);

    try {
      await cancelBillPayment(billPaymentId, Number(id));
    } catch (e) {
      if (!handleErrors(e)) throw e;
      setCancellingPayment(false);
      refetchBill();
    } finally {
      setCancelledPaymentId(billPaymentId);
      refetchBill();

      const cancelledPayment = billPayments.find(
        (payment) => payment.id === billPaymentId
      );

      if (cancelledPayment?.isVoided) {
        setCancellingPayment(false);
      }
    }
  });

  const paymentPayload = useMemo(
    () => ({
      payments: [
        {
          billUrls: [`${billUrl}`],
          amountToPay,
          paymentMethod: payment.method,
          balance,
          signature:
            payment.method === PAYMENT_METHOD.MAIL_CHECK
              ? getSignatureString(payment.signature)
              : undefined,
          bankAccountOption: selectedAccountBalance as BankAccountOption,
          payWhenLienWaiverIsSigned: payment.payWhenLienWaiverIsSigned,
          vendor: {
            id: billVendor.id,
            email: paymentVendorEmail,
          },
          currentDebitDate: formatDate(payment.currentDebitDate, "yyyy-MM-dd"),
        },
      ],
      lienWaivers: payment.customerLienWaivers,
    }),
    [
      billUrl,
      amountToPay,
      payment.method,
      payment.signature,
      balance,
      selectedAccountBalance,
      payment.payWhenLienWaiverIsSigned,
      billVendor.id,
      paymentVendorEmail,
      payment.currentDebitDate,
      payment.customerLienWaivers,
    ]
  );

  const refetchBill = useCallback(() => dispatch(loadBill(id)), [dispatch, id]);

  const onSave = useEvent(() => {
    const snakeCasePayment = transformKeysToSnakeCase(payment);

    dispatch(recordBillUpdate({ payment_details: snakeCasePayment }));
    return save();
  });

  const fromAccountPaymentOptionsFilters = useMemo(
    () => ({
      billIds: id ? [id] : [],
      skip: !enhancedCanPay || !id,
    }),
    [id, enhancedCanPay]
  );

  const costForCardTransaction = useMemo(() => {
    return billVendor && payment.currentDebitDate
      ? ({
          vendor: {
            displayName: `${billVendor.displayName}`,
            email: paymentVendorEmail,
            url: `${billVendor.url}`,
          },
          paymentAccount: selectedAccountBalance?.customerPaymentAccount,
          date: payment.currentDebitDate,
          cardTransaction: currentCardTransaction,
        } as LinkedCost)
      : undefined;
  }, [
    billVendor,
    currentCardTransaction,
    payment.currentDebitDate,
    paymentVendorEmail,
    selectedAccountBalance?.customerPaymentAccount,
  ]);

  const onChangeCardTransaction = useEvent<CardTransactionFieldOnChangeHandler>(
    (_, option) => {
      setCurrentCardTransaction(option);
      const newDate = option?.date as Date;
      setPayment((prev) => ({
        ...prev,
        currentDebitDate: newDate || CURRENT_DATE,
      }));
      setCurrentDebitDate(newDate);
      dispatch(recordBillUpdate({ amount_to_pay: option?.amount || balance }));
    }
  );

  const paymentMethods = useMemo(() => {
    if (!selectedAccountBalance) return [];

    return (
      getPaymentMethods(
        selectedAccountBalance?.paymentMethods.map((item) => item.paymentMethod)
      ) || []
    );
  }, [getPaymentMethods, selectedAccountBalance]);

  const firstPaymentOptionValue = useDeepMemo(
    () => paymentOptionsForBill?.accounts?.[0]?.value,
    [paymentOptionsForBill]
  );

  useEffect(() => {
    if (isPaid) return;

    setPayment((prevState) => {
      const hasChangedVendor =
        prevState.vendorEmail !== vendor.email ||
        prevState.vendorPhoneNumber !== vendor.phoneNumber ||
        prevState.vendorBankingAch !== vendor?.banking?.url ||
        prevState.vendor !== vendor.url;

      return {
        ...prevState,
        accountBalance:
          prevState.accountBalance || firstPaymentOptionValue || "",
        vendorEmail:
          (hasChangedVendor ? vendor.email : prevState.vendorEmail) || "",
        vendorPhoneNumber:
          (hasChangedVendor
            ? vendor.phoneNumber
            : prevState.vendorPhoneNumber) || "",
        vendorBankingAch:
          (hasChangedVendor
            ? vendor?.banking?.url
            : prevState.vendorBankingAch) || "",
        vendor: (hasChangedVendor ? vendor.url : prevState.vendor) || "",
        signature: paymentDetails.signature || currentSignature?.url || "",
      };
    });
  }, [
    isPaid,
    firstPaymentOptionValue,
    vendor?.banking?.url,
    vendor.email,
    vendor.phoneNumber,
    vendor.url,
    paymentDetails.signature,
    currentSignature?.url,
  ]);

  useEffect(() => {
    if (cancelledPaymentId && cancellingPayment) {
      refetchBill();
      const cancelledPayment = billPayments.find(
        (payment) => payment.id === cancelledPaymentId
      );

      if (cancelledPayment?.isVoided) {
        setCancelledPaymentId(null);
        setCancellingPayment(false);
      }
    }
  }, [cancelledPaymentId, cancellingPayment, billPayments, refetchBill]);
  const setDefaultSignature = useCallback(async () => {
    if (!hasSignature) setSignature(await getSignature());
  }, [hasSignature, getSignature]);

  useEffect(() => {
    setDefaultSignature();
  }, [setDefaultSignature]);

  useEffect(() => {
    if (!vendor || billVendor.id != vendor.id) {
      fetchVendor(billVendor.id);
    }
  }, [billVendor.id, fetchVendor, vendor]);

  useEffect(() => {
    setPayment((prev) => {
      const newLienWaivers = billCustomerUrls
        .filter(
          (customer) =>
            !prev.customerLienWaivers.some((item) => item.customer === customer)
        )
        .map((customer) =>
          mapLienWaiverCustomer(customer, billLienWaiverTemplate)
        );

      const remainingLienWaivers = prev.customerLienWaivers.filter((item) => {
        return billCustomerUrls.some((customer) => item.customer === customer);
      });

      return {
        ...prev,
        customerLienWaivers: [...remainingLienWaivers, ...newLienWaivers],
      };
    });
  }, [billCustomerUrls, billLienWaiverTemplate, mapLienWaiverCustomer]);

  return (
    <>
      <div className="steps-section-content">
        <Flex direction="column" padding={["3xl", "5xl"]}>
          <Flex gap="xl" direction="column">
            <BillAlerts />
            <Flex gap="5xl" direction="column">
              {isVendorCredit || isArchivedByUser ? null : (
                <Flex gap="xl" direction="column">
                  <Flex padding={["xs", "none"]}>
                    <Text size="xl" weight="bold">
                      {canManageLienWaivers
                        ? PAYMENT_STEP_FORM_STRINGS.PAYMENT_SECTION_TITLE_WITH_LW
                        : PAYMENT_STEP_FORM_STRINGS.PAYMENT_SECTION_TITLE}
                    </Text>
                  </Flex>

                  <PurchaseOrdersOverBudgetAlert />

                  {!isPaid && (
                    <Flex gap="5xl" direction="column">
                      <Flex gap="xl" direction="column">
                        <Card as={Flex}>
                          <Flex
                            gap="2xl"
                            direction={{ mobile: "column", tablet: "row" }}
                            width="full"
                            separator={{ mobile: false, tablet: true }}
                          >
                            <Flex width="full">
                              <Flex
                                gap="xl"
                                width="full"
                                direction="column"
                                separator
                              >
                                <Flex gap="xl" width="full" direction="column">
                                  <Flex
                                    width="full"
                                    align="center"
                                    gap="xl"
                                    justify="space-between"
                                  >
                                    <Text size="sm">
                                      {
                                        PAYMENT_STEP_FORM_STRINGS.OPEN_BALANCE_TITLE
                                      }
                                    </Text>
                                    <Text weight="bold">
                                      {" "}
                                      {formatCurrency(balance, {
                                        currencySign: true,
                                        allowNegative: true,
                                      })}
                                    </Text>
                                  </Flex>
                                  {vendorCreditsEnabled && (
                                    <Flex direction="column">
                                      <Flex
                                        width="full"
                                        align="center"
                                        gap="xl"
                                        justify="space-between"
                                      >
                                        <Text size="sm">
                                          {
                                            PAYMENT_STEP_FORM_STRINGS.VENDOR_CREDITS_TITLE
                                          }
                                        </Text>
                                        {/* Update this amounts when vendor credits are implemented */}
                                        <Text weight="bold">$0.00</Text>
                                      </Flex>
                                      <Flex
                                        width="full"
                                        align="center"
                                        gap="xl"
                                        justify="space-between"
                                      >
                                        <Text size="sm" color="neutral-500">
                                          {/* Update this amounts when vendor credits are implemented */}
                                          $0 available
                                        </Text>
                                        <Flex
                                          margin={["none", "-13px", "none"]}
                                        >
                                          <Button
                                            size="sm"
                                            variant="text"
                                            color="neutral"
                                            aria-label="Edit vendor credits"
                                          >
                                            <Icon name="pen" />
                                            {
                                              PAYMENT_STEP_FORM_STRINGS.EDIT_CREDITS_TITLE
                                            }
                                          </Button>
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                  )}
                                  <Flex
                                    width="full"
                                    align="center"
                                    gap="xl"
                                    justify="space-between"
                                  >
                                    <Text as="label" size="sm">
                                      {
                                        PAYMENT_STEP_FORM_STRINGS.AMOUNT_TO_PAY_TITLE
                                      }
                                    </Text>
                                    <Flex maxWidth="170px">
                                      <CurrencyField
                                        aria-labelledby="amount-to-pay-label"
                                        value={amountToPay}
                                        disabled={
                                          haveLinkedLienWaiver ||
                                          !enhancedCanPay
                                        }
                                        placeholder="0.00"
                                        helperMessage={
                                          haveLinkedLienWaiver &&
                                          STRINGS.PAYMENT_AMOUNT_DISABLED_LIEN_WAIVER
                                        }
                                        align="right"
                                        onChange={onChangePaymentAmount}
                                        autoFocus
                                        triggerChangeOnFocusedUnmount={false}
                                        messageVariant="absolute"
                                        allowNegative={false}
                                        data-testid="payment-amount"
                                        errorMessage={
                                          amountToPay > balance &&
                                          PAYMENT_STEP_FORM_STRINGS.PAYMENT_AMOUNT_T0_HIGH
                                        }
                                      />
                                    </Flex>
                                  </Flex>
                                </Flex>
                                <Flex
                                  width="full"
                                  align="center"
                                  gap="xl"
                                  justify="space-between"
                                >
                                  <Text size="sm">
                                    {
                                      PAYMENT_STEP_FORM_STRINGS.REMAINING_BALANCE_TITLE
                                    }
                                  </Text>
                                  <Text weight="bold">
                                    {formatCurrency(
                                      sum(balance, -amountToPay),
                                      {
                                        currencySign: true,
                                        allowNegative: true,
                                      }
                                    )}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                            <Flex
                              width="full"
                              justify="flex-start"
                              direction="column"
                            >
                              <Flex width="full" direction="column">
                                <BankAccountComboBox
                                  disabled={isArchivedByUser || !enhancedCanPay}
                                  errorMessage={
                                    enhancedCanPay
                                      ? fromAccountValidation?.failures[0]
                                      : undefined
                                  }
                                  label={
                                    PAYMENT_STEP_FORM_STRINGS.FROM_ACCOUNT_TITLE
                                  }
                                  value={payment.accountBalance}
                                  paymentOptionFilters={
                                    fromAccountPaymentOptionsFilters
                                  }
                                  data-testid="from-account"
                                  onChange={(value) => {
                                    setPayment((previousPayment) => ({
                                      ...previousPayment,
                                      accountBalance: value,
                                    }));
                                  }}
                                />
                                {(fromAccountValidation?.failures?.length ||
                                  0) > 0 &&
                                  !paymentOptionsLoading && (
                                    <Flex
                                      gap="sm"
                                      margin={["none", "none", "lg"]}
                                    >
                                      <Text
                                        size="sm"
                                        color="neutral-700"
                                        weight="regular"
                                      >
                                        {
                                          PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE
                                        }
                                      </Text>
                                      <Link
                                        as="button"
                                        type="button"
                                        variant="success"
                                        onClick={accountSelectDialog.show}
                                      >
                                        {
                                          PAYMENT_STEP_FORM_STRINGS.CLICK_HERE_TITLE
                                        }
                                      </Link>
                                    </Flex>
                                  )}
                              </Flex>
                              {paymentMethods.length ? (
                                <ComboBox
                                  disabled={!enhancedCanPay}
                                  label={
                                    PAYMENT_STEP_FORM_STRINGS.PAYMENT_METHOD_TITLE
                                  }
                                  value={payment.method}
                                  data={paymentMethods}
                                  data-testid="payment-method"
                                  onChange={(value) =>
                                    setPayment((previousPayment) => ({
                                      ...previousPayment,
                                      method: value as PaymentMethod,
                                      currentDebitDate: CURRENT_DATE,
                                    }))
                                  }
                                />
                              ) : null}

                              {payment.method === PAYMENT_METHOD.MAIL_CHECK &&
                                canBenReviewedAndPaid && (
                                  <Flex direction="column">
                                    <TextField
                                      disabled
                                      label={
                                        PAYMENT_STEP_FORM_STRINGS.VENDOR_ADDRESS_TITLE
                                      }
                                      messageVariant="hidden"
                                      value={
                                        vendorAddresses.length
                                          ? vendorAddresses[0].label
                                          : ""
                                      }
                                    />
                                    <Text size="xs" color="neutral-500">
                                      {
                                        PAYMENT_STEP_FORM_STRINGS.ADD_VENDOR_ADDRESS_TITLE
                                      }{" "}
                                      <Link
                                        variant="success"
                                        size="xs"
                                        onClick={curriedOpenVendor("info")}
                                      >
                                        {
                                          PAYMENT_STEP_FORM_STRINGS.ADD_IT_HERE_TITLE
                                        }
                                      </Link>
                                    </Text>
                                  </Flex>
                                )}

                              {(selectedAccountBalance?.customerBankAccount
                                ?.type === "credit" ||
                                selectedAccountBalance?.customerCard) &&
                                selectedAccountBalance.hasUnmatchedCardTransactions &&
                                costForCardTransaction && (
                                  <CardTransactionField
                                    cost={costForCardTransaction}
                                    label={
                                      PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_TITLE
                                    }
                                    cardFilter={
                                      selectedAccountBalance?.customerCard
                                    }
                                    valueVariant="long"
                                    suffix={<Icon size="md" name="merge" />}
                                    placeholder={
                                      PAYMENT_STEP_FORM_STRINGS.CARD_TRANSACTION_PLACEHOLDER
                                    }
                                    onChange={onChangeCardTransaction}
                                    selectCard={{
                                      title:
                                        PAYMENT_STEP_FORM_STRINGS.MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_TITLE,
                                      subtitle:
                                        PAYMENT_STEP_FORM_STRINGS.MATCHING_TRANSACTION_TO_NEW_PAYMENT_DIALOG_SUBTITLE,
                                    }}
                                    daysThreshold={DAYS_THRESHOLD}
                                    skipRequest
                                  />
                                )}

                              <Flex gap="2xl" direction="column">
                                <Flex gap="md" direction="column">
                                  {(achShouldBeRequested ||
                                    selectedAccountBalance?.markAsPaidOption) && (
                                    <Label htmlFor="payment-when-collected">
                                      {
                                        PAYMENT_STEP_FORM_STRINGS.DEBIT_DATE_TITLE
                                      }
                                    </Label>
                                  )}
                                  {selectedAccountBalance?.markAsPaidOption && (
                                    <Flex
                                      gap="xl"
                                      align="end"
                                      justify="space-between"
                                    >
                                      <Flex direction="column">
                                        <Text weight="bold">
                                          {formatDate(payment.currentDebitDate)}
                                        </Text>
                                      </Flex>
                                      <Flex margin={["none", "-13px", "-4px"]}>
                                        <Button
                                          size="sm"
                                          variant="text"
                                          color="neutral"
                                          onClick={showEditDebitDateDialog}
                                        >
                                          <Icon name="pen" />
                                          {
                                            PAYMENT_STEP_FORM_STRINGS.EDIT_DEBIT_DATE_TITLE
                                          }
                                        </Button>
                                      </Flex>
                                    </Flex>
                                  )}
                                  {achShouldBeRequested &&
                                    canBenReviewedAndPaid && (
                                      <Flex direction="column">
                                        <Checkbox
                                          id="payment-when-collected"
                                          label={
                                            PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_ACH_DATA_COLLECTED_TITLE
                                          }
                                          checked
                                          disabled
                                        />
                                        <Text size="xs" color="neutral-500">
                                          {
                                            PAYMENT_STEP_FORM_STRINGS.ADD_ACH_DETAILS_TITLE
                                          }{" "}
                                          <Link
                                            variant="success"
                                            size="xs"
                                            onClick={curriedOpenVendor(
                                              "payments"
                                            )}
                                          >
                                            {
                                              PAYMENT_STEP_FORM_STRINGS.ADD_IT_HERE_TITLE
                                            }
                                          </Link>
                                        </Text>
                                      </Flex>
                                    )}
                                </Flex>
                                {canManageLienWaivers ? (
                                  <Checkbox
                                    label={
                                      PAYMENT_STEP_FORM_STRINGS.PAY_WHEN_LIEN_WAIVER_SIGNED_TITLE
                                    }
                                    onChange={onSetPayWhenLienWaiverIsSigned}
                                    checked={
                                      payment.payWhenLienWaiverIsSigned || false
                                    }
                                  />
                                ) : null}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Card>
                      </Flex>

                      {payment.method === PAYMENT_METHOD.MAIL_CHECK &&
                        currentSignature && (
                          <Card>
                            <Flex direction="column">
                              <Text size="sm">Signature</Text>
                              <Flex padding={["xl", "none"]}>
                                <Image
                                  src={currentSignature.url}
                                  width={currentSignature.width}
                                  height={currentSignature.height}
                                />
                              </Flex>
                              <Button
                                variant="ghost"
                                color="primary"
                                onClick={updateSignatureDialog.show}
                              >
                                Update signature
                              </Button>
                            </Flex>
                            <UpdateSignatureDialog
                              dialog={updateSignatureDialog}
                              signature={currentSignature.name}
                              defaultSignature={user.full_name}
                              onChange={async (value) => {
                                setSignature(await getSignature(value));
                              }}
                            />
                          </Card>
                        )}

                      {canManageLienWaivers && balance > 0 && (
                        <BillLienWaivers
                          lienWaiverCustomers={payment.customerLienWaivers}
                          onChange={onChangeLienWaiverTemplate}
                          recipientEmail={paymentVendorEmail}
                          vendor={vendor}
                          paymentAmount={amountToPay || undefined}
                          billId={id}
                          billLienWaiver={lienWaiver}
                          onRequestUpdate={onChangeLienWaiverRequest}
                          requestOnPayment={requestOnPayment}
                          billInternalVersion={billInternalVersion}
                        />
                      )}
                    </Flex>
                  )}

                  <Wrapper
                    when={cardFeedEnabled && billPayments?.length > 0}
                    render={(children) => (
                      <Flex
                        gap="2xl"
                        direction="column"
                        padding={["none", "none", "xl"]}
                      >
                        {children}
                      </Flex>
                    )}
                  >
                    {billPayments.map((payment) => (
                      <BillPaymentInfoV2
                        key={payment.id}
                        billPayment={payment}
                        onViewPayment={onViewPayment}
                      />
                    ))}
                  </Wrapper>
                </Flex>
              )}

              <Flex gap="xl" direction="column">
                <Form onEnterSubmit={onSave}>
                  <Info />
                </Form>
              </Flex>

              {enhancedShouldShowPurchaseOrders && <PurchaseOrders />}

              <Form onEnterSubmit={onSave}>
                <Items />
              </Form>

              {/**
               * We need it as backward compatibility since old bills could not have
               * workflows attached, new bills will always have at least one workflow
               */}
              {workflows.length > 0 && (
                <ApprovalsSection
                  editable={false}
                  objectId={Number(id)}
                  objectType="Bill"
                  workflows={workflows}
                  helperMessage={(mode) => (mode === "EMPTY" ? false : "")}
                />
              )}

              <Comments />
            </Flex>
          </Flex>
        </Flex>
      </div>

      <footer className="steps-section-footer">
        <Flex gap="xl">
          <Button variant="text" size="lg" color="neutral" onClick={onClose}>
            {PAYMENT_STEP_FORM_STRINGS.CANCEL_TITLE}
          </Button>
          <DynamicActions />
        </Flex>
        <Flex gap="xl">
          <Tooltip
            message={
              permissions.canEditBill
                ? tooManyLines
                  ? STRINGS.BUTTON_TOO_MANY_LINES
                  : undefined
                : STRINGS.ACTION_NOT_ALLOWED
            }
            placement="left"
          >
            <Button
              disabled={
                isArchivedByUser ||
                !permissions.canEditBill ||
                isLoadingMarkingAsPaid ||
                tooManyLines
              }
              variant={
                selectedAccountBalance?.markAsPaidOption ? "text" : "ghost"
              }
              size="lg"
              onClick={onSave}
            >
              {PAYMENT_STEP_FORM_STRINGS.SAVE_TITLE}
            </Button>
          </Tooltip>
          {selectedAccountBalance?.markAsPaidOption && !isVendorCredit && (
            <Tooltip
              message={
                permissions.canEditBill
                  ? hasValidationErrors
                    ? validationTooltip
                    : undefined
                  : STRINGS.ACTION_NOT_ALLOWED
              }
              placement="left"
            >
              <Button
                disabled={
                  hasValidationErrors ||
                  isArchivedByUser ||
                  !permissions.canEditBill ||
                  !selectedAccountBalance ||
                  isLoadingMarkingAsPaid ||
                  paymentOptionsLoading
                }
                variant="ghost"
                size="lg"
                onClick={curriedMarkAsPaid}
              >
                {PAY_BUTTON_LABEL.MARK_AS_PAID}
              </Button>
            </Tooltip>
          )}
          {getPrimaryActionButtons()}
        </Flex>
      </footer>
      {editDebitDateDialog.isRendered && (
        <Dialog
          show={editDebitDateDialog.isVisible}
          variant="dialog"
          onClose={hideEditDebitDateDialog}
        >
          <DialogHeader>
            {PAYMENT_STEP_FORM_STRINGS.SELECT_DEBIT_DATE_TITLE}
          </DialogHeader>
          <DialogContent>
            <Flex gap="3xl">
              <DatePicker
                toDate={CURRENT_DATE}
                value={currentDebitDate}
                onChange={(value) => setCurrentDebitDate(value as Date)}
                highlighted={highlightedDates}
              />
              <Flex gap="md" direction="column">
                <Text>{PAYMENT_STEP_FORM_STRINGS.DEBIT_TITLE}</Text>
                <Text weight="bold">{formatDate(currentDebitDate)}</Text>
                <Text size="sm">
                  {PAYMENT_STEP_FORM_STRINGS.CANNOT_SELECT_FUTURE_DATES}
                </Text>
              </Flex>
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Button
              variant="text"
              block
              size="lg"
              onClick={hideEditDebitDateDialog}
            >
              {PAYMENT_STEP_FORM_STRINGS.CANCEL_TITLE}
            </Button>
            <Button
              variant="solid"
              size="lg"
              block
              onClick={() => {
                setPayment((previousPayment) => ({
                  ...previousPayment,
                  currentDebitDate: currentDebitDate as Date,
                }));
                hideEditDebitDateDialog();
              }}
            >
              {PAYMENT_STEP_FORM_STRINGS.SAVE_TITLE}
            </Button>
          </DialogFooter>
        </Dialog>
      )}
      {showPaymentConfirmationDialog &&
        id &&
        billUrl &&
        selectedAccountBalance &&
        vendor && (
          <BillPaymentConfirmationDialog
            onClose={showPaymentConfirmationDialog.hide}
            paymentsInfo={paymentPayload}
            dialog={showPaymentConfirmationDialog}
          />
        )}
      {accountSelectDialog.isRendered && (
        <AccountSelectDialog
          dialog={accountSelectDialog}
          selectHeader={PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
          selectSubHeader={`Bank account: ${selectedAccountBalance?.mask}`}
          selectLabel={PAYMENT_STEP_FORM_STRINGS.LINK_ACCOUNT_TITLE}
          putObject={(values) => {
            patchAccountBalance({
              account: selectedAccountBalance?.customerBankAccount,
              ...values,
            }).then(() => {
              refetchPaymentOptions();
            });
          }}
          isBankAccount={true}
          selectedAccount={undefined}
        />
      )}
    </>
  );
};
